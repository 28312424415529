interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721636433/review-1.png',
    story: `"My previously shy puppy is now confident and friendly with other animals and people. The emphasis on positive reinforcement really helped reduce his fear based aggression. It’s a must read!"`,
    author: 'Radvile, 35',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721636433/review-5.png',
    story:
      '"My dog’s behavior during walks improved soo much. She used to pull constantly, but now she walks calmly beside me, I’m so happy! The book covers everything from basics to more advanced techniques."',
    author: 'Olivia S., 27',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721636433/review-3.png',
    story:
      '"The Raising Dog book is fantastic! It covers everything from basic commands to more advanced techniques. My dog’s behavior improved significantly after following the book’s advice. I’m so happy!"',
    author: 'Emma, 26',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721636434/review-4.png',
    story:
      '"Charlie used to bark at every little noise, he was uncontrollable and SO reactive. Now he only barks when necessary and he stopped ignoring my commands! This book has literally been a gamechanger."',
    author: 'Lilly T., 32',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721636433/review-6.png',
    story:
      '"This book is A-MA-ZING! Teddy is already learning so much. He used to be terrible with housebreaking and this book changed it in two weeks! I’m thrilled with the progress we’ve made."',
    author: 'Isabella L., 24',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1721638533/review-7.png',
    story:
      '"My dog is now well trained, happy, and healthy. She used to chew everything in sight, but the strategies in the book helped me redirect her chewing to toys. The book is full of practical tips."',
    author: 'Grace W., 23',
  },
];
