import React, { FC } from 'react';
import styled from 'styled-components';

interface VideoCardProps {
  video: string;
  title: string;
  description: string;
  reviewer: string;
}

const VideoReviewsCard: FC<VideoCardProps> = ({
  video,
  title,
  description,
  reviewer,
}) => (
  <StyledCard>
    <video width="258" height="288" autoPlay playsInline muted loop>
      <source src={video} type="video/mp4" />
    </video>
    <StyledReview>
      <strong>{title}</strong>
      <p>{description}</p>
      <strong>{reviewer}</strong>
    </StyledReview>
  </StyledCard>
);

export default VideoReviewsCard;

const StyledCard = styled.div`
  width: 258px;

  video {
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    margin-bottom: -4px;
  }
`;

const StyledReview = styled.div`
  display: flex;
  width: 100%;
  max-width: 258px;
  min-height: 144px;
  padding: 16px 12px 12px 12px;
  flex-direction: column;
  border-radius: 0px 0px 10px 10px;
  background: #f6f4ef;

  strong:first-of-type {
    margin-bottom: 6px;
  }

  strong {
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 8px;
  }
`;
