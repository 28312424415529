import React, { useEffect } from 'react';
import Header from '../../components/reviews/Header';
import MainReviewSection from 'components/reviews/MainReviewSection';
import VideoReviewsSection from 'components/reviews/VideoReviewsSection';
import DogOwnersSection from 'components/reviews/DogOwnersSection';
import SuccessStoriesSection from 'components/reviews/SuccessStoriesSection';
import styled from 'styled-components';
import Footer from 'components/reviews/Footer';
import { Seo } from 'components';
import LCFooter from 'components/LCFooter';

const Reviews = () => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Seo
        title="Raising Dog Review"
        name="Raising Dog Review"
        description="Discover why Raising Dog has thousands of happy users all over the world! Read Raising Dog book reviews, success stories, and customer insights."
      />
      <Header isMobileApp={false} />
      <MainReviewSection />
      <VideoReviewsSection />
      <DogOwnersSection />
      <SuccessStoriesSection />
      <LCFooter disclaimerText={['© 2024 Raising Dog. All rights reserved.']} />
    </>
  );
};

export default Reviews;

export const StyledSection = styled.section<{
  backgroundColor: string;
  marginTop: string;
}>`
  height: fit-content;
  width: 100%;
  background: ${({ backgroundColor = '#f6f4ef' }) => backgroundColor};
  margin-top: ${({ marginTop = '69px' }) => marginTop};
  padding: 56px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  p,
  strong {
    color: #000 !important;
  }

  @media (max-width: 48em) {
    padding: 38px 16px;
  }
`;

export const SectionHeading = styled.h1<{ marginBottom: string }>`
  color: #284634;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '8px' }) => marginBottom};
`;

export const SectionSubheading = styled.h2<{ marginBottom: string }>`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #595b60;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '10px' }) => marginBottom};
`;
