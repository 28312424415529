interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721818702/1.mp4',
    title: 'I’m amazed',
    description: `"Both of my puppies' behavior changed in a few days! They used to ignore my commands, but now they both sit and come without hesitation.”`,
    name: 'Jenny F.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721818701/2.mp4',
    title: 'Thank you!',
    description: `"Teaching my pup even the most basic commands like “sit” seemed impossible before, but now he follows “leave it”, “stay” and many more with no delay."`,
    name: 'Alex P.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721818702/3.mp4',
    title: 'This book is godsend!',
    description: `"As a first time dog owner, this book made training so smooth! My dog used to be uncontrollable on walks, but now he obeys consistently."`,
    name: 'Jackie S.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721818703/4mp4.mp4',
    title: 'Me and my dog are very happy',
    description: `"We struggled with basic training, my dog used to completely ignore my commands but the book helped me teach him commands like down and heel so fast!"`,
    name: 'Rosey F.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721818704/5.mp4',
    title: 'Improved our relationship',
    description: `"Raising dog helped me turn my stubborn lil’ bandit into an obedient dog. The guide on teaching commands like 'wait' and 'come' was a game changer for us."`,
    name: 'Emma Ruth',
  },
];
