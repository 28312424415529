interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Iris Harmon',
    comment:
      'Training course in the book is great, it also has a lot of features to get to know and understand your puppy better.\nLove it!!!!!',
  },
  {
    author: 'Ruby G.',
    comment:
      'I learned that every breed is different, so is care. I enjoyed every minute of learning from this book. Love it!',
  },
  {
    author: 'Garret J.',
    comment: `I'm so glad I had the chance to read this book as it helped me a lot with training my new puppy.`,
  },
  {
    author: 'Brie Nicols',
    comment:
      'Great book. My labradoodle learned a few new tricks thanks to it. Do recommend.',
  },
  {
    author: 'Alan',
    comment:
      'The book really did wonders for my furry friend. He is learning fast and he is so happy.',
  },
  {
    author: 'Alameda',
    comment:
      'Training my reactive Aussie is so much easier now that I have the book, I feel like a dog trainer!',
  },
];
